import { useRef } from "react";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Webfooter from "../components/home/Webfooter";

//EmailJS
import emailjs from "emailjs-com";

const Signup = () => {
  const modalRef = useRef();

  // const SERVICE_ID = "service_t2dg79k";
  // const TEMPLATE_ID = "template_nfuqglj";
  // const PUBLIC_KEY = "ofsSAsCHKcb7i2HTM";

  const SERVICE_ID = "service_t6p9dfm";
  const TEMPLATE_ID = "template_jirn455";
  const PUBLIC_KEY = "j3d9PEotKQndgv33y";

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        modalRef.current.showModal();
        // toast.success(<p>Congratulations! <br /><br />
        // You're now one step closer to joining the kriyaHR family and transforming your business. <br /><br />
        // You'll receive an email confirming your spot, and we'll be in touch very soon.
        // <br /><br />Thank you for trusting kriyaHR!</p>, {
        //   position: "top-center"
        // });
      },
      (error) => {
        console.log(error.text);
        toast.error("Something went wrong!", {
          position: "top-left",
        });
      }
    );
    e.target.reset();
  };

  return (
    <>
      <ToastContainer />
      <div className="pt-32">
        <div className="max-w-[1300px] px-10 h-full m-auto flex flex-col items-center lg:flex-row justify-center gap-10">
          <div className="flex-1 flex flex-col items-start">
            <svg
              viewBox="0 0 655 540"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-[300px] hidden lg:block"
            >
              <g clip-path="url(#clip0_6154_17136)">
                <path
                  d="M19.8984 538.23C19.8984 538.89 20.4284 539.42 21.0884 539.42H750.378C751.038 539.42 751.568 538.89 751.568 538.23C751.568 537.57 751.038 537.04 750.378 537.04H21.0884C20.4284 537.04 19.8984 537.57 19.8984 538.23Z"
                  fill="#3F3D58"
                />
                <path
                  d="M253.432 95.15H19.5316C10.6116 95.15 3.35156 87.89 3.35156 78.97C3.35156 70.05 10.6116 62.79 19.5316 62.79H253.432C262.352 62.79 269.612 70.05 269.612 78.97C269.612 87.89 262.352 95.15 253.432 95.15ZM19.5316 64.79C11.7116 64.79 5.35156 71.15 5.35156 78.97C5.35156 86.79 11.7116 93.15 19.5316 93.15H253.432C261.252 93.15 267.612 86.79 267.612 78.97C267.612 71.15 261.252 64.79 253.432 64.79H19.5316Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M253.432 284.5H19.5316C10.8816 284.5 3.85156 277.47 3.85156 268.82C3.85156 260.17 10.8816 253.14 19.5316 253.14H253.432C262.082 253.14 269.112 260.17 269.112 268.82C269.112 277.47 262.082 284.5 253.432 284.5Z"
                  fill="#FB710D"
                />
                <path
                  d="M176.78 31.36H15.68C7.03 31.36 0 24.33 0 15.68C0 7.03 7.03 0 15.68 0H176.78C185.43 0 192.46 7.03 192.46 15.68C192.46 24.33 185.43 31.36 176.78 31.36Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M253.432 158.43H19.5316C10.6116 158.43 3.35156 151.17 3.35156 142.25C3.35156 133.33 10.6116 126.07 19.5316 126.07H253.432C262.352 126.07 269.612 133.33 269.612 142.25C269.612 151.17 262.352 158.43 253.432 158.43ZM19.5316 128.07C11.7116 128.07 5.35156 134.43 5.35156 142.25C5.35156 150.07 11.7116 156.43 19.5316 156.43H253.432C261.252 156.43 267.612 150.07 267.612 142.25C267.612 134.43 261.252 128.07 253.432 128.07H19.5316Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M253.432 221.72H19.5316C10.6116 221.72 3.35156 214.46 3.35156 205.54C3.35156 196.62 10.6116 189.36 19.5316 189.36H253.432C262.352 189.36 269.612 196.62 269.612 205.54C269.612 214.46 262.352 221.72 253.432 221.72ZM19.5316 191.36C11.7116 191.36 5.35156 197.72 5.35156 205.54C5.35156 213.36 11.7116 219.72 19.5316 219.72H253.432C261.252 219.72 267.612 213.36 267.612 205.54C267.612 197.72 261.252 191.36 253.432 191.36H19.5316Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M11.4898 51.1699H72.7898C74.2498 51.1699 75.4398 52.3499 75.4398 53.8199C75.4398 55.2799 74.2498 56.4699 72.7898 56.4699H11.4898C10.0298 56.4699 8.83984 55.2899 8.83984 53.8199C8.83984 52.3599 10.0298 51.1699 11.4898 51.1699Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M11.4898 115.95H72.7898C74.2498 115.95 75.4398 117.13 75.4398 118.59C75.4398 120.05 74.2498 121.24 72.7898 121.24H11.4898C10.0298 121.24 8.83984 120.06 8.83984 118.6C8.83984 117.14 10.0298 115.95 11.4898 115.95Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M11.4898 180.74H72.7898C74.2498 180.74 75.4398 181.92 75.4398 183.39C75.4398 184.85 74.2498 186.03 72.7898 186.03H11.4898C10.0298 186.03 8.83984 184.85 8.83984 183.38C8.83984 181.92 10.0298 180.74 11.4898 180.74Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M447.079 132.26L424.719 139.62V107.43H445.009L447.079 132.26Z"
                  fill="#9F616A"
                />
                <path
                  d="M427.487 116.34C439.792 116.34 449.767 106.365 449.767 94.0598C449.767 81.7549 439.792 71.7798 427.487 71.7798C415.182 71.7798 405.207 81.7549 405.207 94.0598C405.207 106.365 415.182 116.34 427.487 116.34Z"
                  fill="#9F616A"
                />
                <path
                  d="M433.609 91.8499C429.879 91.7399 427.429 87.9699 425.979 84.5299C424.529 81.0899 423.039 77.1399 419.579 75.7199C416.749 74.5599 411.759 82.4099 409.529 80.3199C407.199 78.1399 409.469 66.9499 411.939 64.9399C414.409 62.9299 417.789 62.5399 420.969 62.3899C428.729 62.0299 436.539 62.6599 444.149 64.2499C448.859 65.2299 453.699 66.7099 457.099 70.1099C461.399 74.4299 462.499 80.9399 462.809 87.0299C463.129 93.2599 462.769 99.7799 459.739 105.23C456.709 110.68 450.369 114.7 444.289 113.31C443.679 110.01 444.299 106.62 444.539 103.26C444.769 99.9099 444.529 96.2899 442.479 93.6199C440.439 90.9499 436.059 89.8899 433.679 92.2599"
                  fill="#2F2E43"
                />
                <path
                  d="M461.019 99.5696C463.249 97.9396 465.919 96.5696 468.659 96.9096C471.619 97.2696 474.129 99.7096 474.889 102.6C475.649 105.49 474.799 108.67 472.959 111.03C471.129 113.39 468.399 114.95 465.519 115.73C463.849 116.18 462.019 116.37 460.429 115.69C458.089 114.68 456.819 111.69 457.739 109.31"
                  fill="#2F2E43"
                />
                <path
                  d="M375.759 309.2C374.269 316.52 376.999 323.21 381.839 324.14C386.679 325.07 391.809 319.88 393.289 312.56C393.919 309.64 393.819 306.62 392.999 303.74L411.429 188.99L388.379 184.65L379.479 301.15C377.589 303.51 376.319 306.27 375.759 309.21V309.2Z"
                  fill="#9F616A"
                />
                <path
                  d="M424.477 124.85H408.747C397.627 126.54 394.607 132.47 392.077 143.43C388.217 160.15 383.287 182.41 384.267 182.74C385.837 183.26 412.617 195.86 426.267 192.98L424.477 124.85Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M439.69 490.36H418.75V520.07H439.69V490.36Z"
                  fill="#9F616A"
                />
                <path
                  d="M398.36 538.05C396.16 538.05 394.2 538 392.72 537.86C387.16 537.35 381.85 533.24 379.18 530.84C377.98 529.76 377.6 528.04 378.22 526.56C378.67 525.5 379.56 524.7 380.67 524.39L395.37 520.19L419.17 504.13L419.44 504.61C419.54 504.79 421.88 509 422.66 511.84C422.96 512.92 422.88 513.82 422.43 514.52C422.12 515 421.68 515.28 421.33 515.44C421.76 515.89 423.11 516.81 427.27 517.47C433.34 518.43 434.62 512.14 434.67 511.88L434.71 511.67L434.89 511.55C437.78 509.69 439.56 508.84 440.17 509.02C440.55 509.13 441.19 509.33 442.92 526.46C443.09 527 444.3 530.94 443.48 534.71C442.59 538.81 424.67 537.4 421.08 537.08C420.98 537.09 407.56 538.05 398.37 538.05H398.36Z"
                  fill="#2F2E43"
                />
                <path
                  d="M499.315 467.018L481.547 478.099L497.269 503.308L515.037 492.227L499.315 467.018Z"
                  fill="#9F616A"
                />
                <path
                  d="M475.72 533.98C473.26 533.98 471 533.68 469.39 533.4C467.81 533.12 466.57 531.86 466.31 530.28C466.13 529.14 466.46 527.99 467.24 527.14L477.49 515.8L489.19 489.58L489.67 489.84C489.85 489.94 494.06 492.27 496.23 494.27C497.06 495.03 497.47 495.84 497.45 496.67C497.44 497.25 497.22 497.71 497 498.04C497.6 498.2 499.23 498.26 503.11 496.62C508.77 494.23 506.53 488.21 506.43 487.96L506.35 487.76L506.44 487.57C507.91 484.46 508.96 482.8 509.58 482.63C509.97 482.52 510.61 482.35 521.14 495.98C521.57 496.34 524.68 499.05 525.98 502.68C527.39 506.63 511.44 514.92 508.23 516.54C508.13 516.62 491.44 528.75 484.58 532.2C481.86 533.57 478.64 533.99 475.71 533.99L475.72 533.98Z"
                  fill="#2F2E43"
                />
                <path
                  d="M455.11 241.91H396.48L391.16 296.45L414.44 497.97H444.37L432.4 381.58L480.95 486.66L507.55 468.04L469.64 369.94C469.64 369.94 483.18 284.48 472.54 263.19C461.9 241.91 455.11 241.91 455.11 241.91Z"
                  fill="#2F2E43"
                />
                <path
                  d="M484.28 245.23H391.16L419.1 124.85H459.67L484.28 245.23Z"
                  fill="#E2E3E4"
                />
                <path
                  d="M492.658 309.2C494.148 316.52 491.418 323.21 486.578 324.14C481.738 325.07 476.608 319.88 475.128 312.56C474.498 309.64 474.598 306.62 475.418 303.74L456.988 188.99L480.038 184.65L488.938 301.15C490.828 303.51 492.098 306.27 492.658 309.21V309.2Z"
                  fill="#9F616A"
                />
                <path
                  d="M443.938 124.85H459.668C470.788 126.54 473.808 132.47 476.338 143.43C480.198 160.15 485.128 182.41 484.148 182.74C482.578 183.26 455.798 195.86 442.148 192.98L443.938 124.85Z"
                  fill="#E2E3E4"
                />
              </g>
              <defs>
                <clipPath id="clip0_6154_17136">
                  <rect width="751.57" height="539.42" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <h2 className="mt-10 text-[32px] font-bold font-['Montserrat'] text-black-blue">
              Be a part of the kriya family!
            </h2>

            <p className="font-['Montserrat'] text-gray-500">
              Sign up today to enjoy exclusive discounts, early access
              notifications, priority onboarding, dedicated support, and full
              access to our HRIS, payroll, and compliance tools.
            </p>
          </div>

          <div className="flex-1">
            <h1 className="font-['Montserrat'] text-black-blue text-4xl font-bold ">
              Join the waitlist!
            </h1>

            <form class="formContainer" onSubmit={handleOnSubmit}>
              <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-6">
                <div>
                  <label className="font-['Montserrat'] text-steel text-sm">
                    Name<span className="text-red-500"> *</span>
                  </label>

                  <input
                    type="text"
                    className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                    placeholder="John Doe"
                    id="from_name"
                    name="from_name"
                    required
                  />
                </div>

                <div>
                  <label className="font-['Montserrat'] text-steel text-sm">
                    Company name<span className="text-red-500"> *</span>
                  </label>

                  <input
                    type="text"
                    className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                    placeholder="Company ABC"
                    id="company"
                    name="company"
                    required
                  />
                </div>

                <div>
                  <label className="font-['Montserrat'] text-steel text-sm">
                    Email<span className="text-red-500"> *</span>
                  </label>

                  <input
                    type="email"
                    className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                    placeholder="name@email.com"
                    id="from_email"
                    name="from_email"
                    required
                  />
                </div>

                <div>
                  <label className="font-['Montserrat'] text-steel text-sm">
                    Phone number
                  </label>

                  <input
                    type="number"
                    className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                    placeholder="09XXXXXXXXX"
                    id="phone_number"
                    name="phone_number"
                  />
                </div>

                <div>
                  <label className="font-['Montserrat'] text-steel text-sm">
                    Number of employees
                  </label>

                  <input
                    type="text"
                    className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                    placeholder=""
                    id="num_employees"
                    name="num_employees"
                  />
                </div>

                <div className="lg:col-span-2">
                  <label className="font-['Montserrat'] text-steel text-sm">
                    Comments/Inquiries
                  </label>

                  <textarea
                    id="comment"
                    name="comment"
                    className="font-['Montserrat'] outline-none px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue h-[100px] resize-none"
                  />
                </div>
              </div>

              <div className="flex justify-end mt-5">
                <button className="outline-none bg-black-blue px-6 py-2 text-white rounded-full">
                  Sign up Now
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="mt-5">
          <Webfooter />
        </div>
      </div>

      <dialog className="modal">
        <div className="modal-box">
          <div className="flex flex-row justify-between">
            <h1 className="text-xl font-semibold">Successfully Signed Up!</h1>

            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                ✕
              </button>
            </form>
          </div>
          <form action="" method="dialog">
            <div className="flex flex-col gap-2 mx-5 mt-10 md:mx-10">
              <p>Congratulations!</p>
              <br></br>

              <p>
                You're now one step closer to joining the kriyaHR family and
                transforming your business. <br />
                <br />
              </p>

              <p>
                You'll receive an email confirming your spot, and we'll be in
                touch very soon.{" "}
              </p>
              <br />
              <br />
              <p>Thank you for trusting kriyaHR!</p>

              <button
                className="btn btn-active normal-case btn-md"
                onClick={() => modalRef.current.close()}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </dialog>

      <dialog
        ref={modalRef}
        className="outline-none rounded-xl shadow-x w-[600px] p-5"
      >
        <div className="flex gap-10">
          <div className="flex-1">
            <button
              onClick={() => modalRef.current.close()}
              className="absolute right-3 top-3 w-5 h-5 flex justify-center items-center hover:bg-slate-200 rounded-full text-sm"
            >
              ✕
            </button>

            <h2 className="text-2xl font-bold text-black-blue">
              Successfully Signed Up!
            </h2>

            <p className="mt-5 text-sm text-gray-500">
              You're now one step closer to joining the kriyaHR family!
            </p>

            <p className="mt-2 text-sm text-gray-500">
              You'll receive an email confirming your spot, and we'll be in
              touch very soon.
            </p>

            <p className="mt-4 text-sm text-gray-500">
              Thank you for trusting kriyaHR!
            </p>

            <button
              className="w-full px-6 py-2 bg-pumpkin outline-none text-white mt-5 rounded-lg text-sm"
              onClick={() => modalRef.current.close()}
            >
              Close
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default Signup;
