import Webfooter from "../components/home/Webfooter";
import FadeInUp from "../components/transitions/FadeInUp";

const PrivacyPolicy = () => {
  return (
    <div className=" flex flex-col pt-32">
      <div className="max-w-[1300px] m-auto box-border p-10 min-h-screen  text-black-blue ">
        <FadeInUp>
          <h1 className=" text-center lg:text-left text-3xl md:text-5xl font-bold">
            Privacy Policy
          </h1>

          <p className="text-sm md:text-lg py-12">
            At<span className="font-bold"> kriyaHR</span> ("Company"), we are
            dedicated to protecting your personal information and ensuring your
            data privacy. We process all personal data in accordance with
            Republic Act No. 10173, also known as the
            <span className="font-bold">
              {" "}
              Data Privacy Act of 2012 of the Philippines
            </span>
            . By accessing our website, using our services, or submitting your
            information to us, you consent to the collection, use, processing,
            and storage of your personal data as outlined in this Privacy
            Policy. This consent is given freely and may be withdrawn at any
            time, subject to the conditions set forth herein.
          </p>

          <h3 className="text-2xl font-bold">1. Information Collection</h3>
          <p className="text-sm pt-5 md:text-lg">
            We collect personal information from clients, users, and visitors
            through our website, events, and services. This may include:
          </p>

          <p className="text-sm md:text-lg">
            <ul className="list-outside px-5 pt-5 pb-2">
              <li>
                <span className="font-bold"> A. Personal Information </span>
                <ul className="list-disc list-inside">
                  <li>Name</li>
                  <li>Email Address</li>
                  <li>Contact Number</li>
                  <li>Company</li>
                </ul>
              </li>
            </ul>
          </p>
          <p className="text-sm md:text-lg">
            <ul className="list-outside px-5 pt-2">
              <li>
                <span className="font-bold"> B. Web Behavior Information:</span>
                <ul className="list-disc list-inside">
                  <li>IP Address</li>
                  <li>Browser Type</li>
                  <li>Access Times</li>
                </ul>
              </li>
            </ul>
          </p>
          <p className="text-sm md:text-lg">
            <ul className="list-outside px-5 pt-2">
              <li>
                <span className="font-bold"> C. Employment Information:</span>
                <ul className="list-disc list-inside">
                  <li>Employment Status</li>
                  <li>Benefits Information</li>
                  <li>Attendance Records</li>
                  <li>Leave Applications</li>
                </ul>
              </li>
            </ul>
          </p>
          <p className="text-sm md:text-lg">
            <ul className="list-outside px-5 pt-2">
              <li>
                <span className="font-bold">
                  {" "}
                  D. Employment Files and Documents:
                </span>
                <ul className="list-disc list-inside">
                  <li>Resumes and CVs</li>
                  <li>Government-issued IDs and Permits</li>
                  <li>Contracts and Agreements</li>
                  <li>Academic Records and Certifications</li>
                  <li>Notices and Memos</li>
                </ul>
              </li>
            </ul>
          </p>

          <p className="text-sm pb-12 pt-5 md:text-lg">
            We may also collect non-personally identifiable information for
            statistical analysis and service improvement.
          </p>

          <h3 className="text-2xl font-bold">
            2. Use and Purpose of Collected Data
          </h3>
          <p className="text-sm pt-5  md:text-lg">
            Your personal information may be used for the following purposes:
          </p>
          <p className="text-sm md:text-lg">
            <ul className="list-outside px-5 pt-2">
              <li>
                <ul className="list-disc list-inside">
                  <li>
                    <span className="font-bold"> Service Provision:</span> To
                    deliver and improve our HR and payroll solutions.
                  </li>
                  <li>
                    <span className="font-bold">Transaction Facilitation:</span>{" "}
                    To process transactions and provide customer support.
                  </li>
                  <li>
                    <span className="font-bold">Communication: </span>To send
                    updates, respond to inquiries, and provide information about
                    our products and services.
                  </li>
                  <li>
                    <span className="font-bold">Compliance: </span>To adhere to
                    legal obligations and regulatory requirements.
                  </li>
                  <li>
                    <span className="font-bold"> Marketing Activities:</span> To
                    inform you about promotions, events, and new offerings, with
                    your consent.
                  </li>
                  <li>
                    <span className="font-bold"> Research and Analysis: </span>
                    To conduct statistical analyses for service enhancement.
                  </li>
                  <li>
                    <span className="font-bold">Security:</span> To detect and
                    prevent fraud, abuse, or other harmful activities
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p className="text-sm pb-12 pt-5 md:text-lg">
            We may share your information within our internal departments, with
            affiliates, partners, and trusted third-party service providers who
            assist us in operating our business. These parties are obligated to
            keep your information confidential and use it only for the purposes
            for which we have engaged them.
          </p>

          <h3 className="text-2xl font-bold">3. Data Security</h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            We implement reasonable and appropriate security measures to protect
            your personal information from unauthorized access, disclosure,
            alteration, or destruction. These measures include physical,
            electronic, and procedural safeguards in compliance with applicable
            laws and regulations. Access to your personal data is restricted to
            authorized personnel who require it to perform their duties.
          </p>

          <h3 className="text-2xl font-bold">4. Data Retention</h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy, unless a
            longer retention period is required or permitted by law. When your
            personal data is no longer needed, we will securely dispose of it in
            accordance with our data retention policies.
          </p>

          <h3 className="text-2xl font-bold">5. Third-Party Disclosure</h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            We do not sell, trade, or transfer your personal information to
            third parties without your consent. However, we may share your data
            with trusted third-party service providers who assist us in
            delivering our services, under strict confidentiality agreements. We
            may also disclose your information when required by law or in
            response to valid legal processes to protect our rights, property,
            or safety, or that of our clients and others.
          </p>

          <h3 className="text-2xl font-bold">6. Your Rights</h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            Under the Data Privacy Act of 2012, you have specific rights
            regarding your personal data. You have the right to be informed
            about how your personal data is collected, used, and processed. You
            can request access to your personal data at any time. In certain
            circumstances, you have the right to object to the processing of
            your personal data. If your personal data is incorrect, outdated, or
            unlawfully obtained, you can request its deletion or blocking. You
            also have the right to seek compensation for any damages sustained
            due to inaccurate or unauthorized use of your personal data. If you
            find that your personal data is inaccurate or incomplete, you have
            the right to request corrections. Additionally, you have the right
            to data portability, allowing you to obtain a copy of your personal
            data in an electronic or structured format.
          </p>

          <h3 className="text-2xl font-bold">
            7. Online Inquiries and Log Files
          </h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            We collect information through log files and cookies to enhance your
            experience on our website. This includes non-personally identifiable
            information such as browser type, access times, and referring
            website addresses. This data helps us analyze trends, administer the
            site, and gather demographic information.
          </p>

          <h3 className="text-2xl font-bold">8. Marketing Activities</h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            With your consent, we may use your contact information to send you
            promotional materials and updates about our products and services.
            You may opt-out of receiving these communications at any time by
            following the unsubscribe instructions provided in the message or by
            contacting us.
          </p>

          <h3 className="text-2xl font-bold">
            9. Compliance with Legal Obligations
          </h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            We may disclose your personal information if required to do so by
            law or in response to valid requests by public authorities. This
            includes complying with legal processes, enforcing our policies, and
            protecting the rights, property, or safety of kriyaHR, our clients,
            or others.
          </p>

          <h3 className="text-2xl font-bold">
            10. Updates to This Privacy Policy
          </h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            We may update this Privacy Policy from time to time to reflect
            changes in our data practices or legal requirements. Any updates
            will be posted on this page with an updated effective date. We
            encourage you to review this policy periodically.
          </p>

          <h3 className="text-2xl font-bold">11. Consent</h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            By using our website, services, or by providing your personal
            information, you acknowledge that you have read and understood this
            Privacy Policy and agree to the collection, use, and processing of
            your personal data as described herein.
          </p>

          <h3 className="text-2xl font-bold">12. Contact Information</h3>
          <p className="text-sm pt-5  md:text-lg  pb-12">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or your personal data, please contact our Data
            Protection Officer:{" "}
            <span className="font-bold">tech@kriyahr.com</span>
          </p>
        </FadeInUp>
      </div>
      <div>
        <Webfooter />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
