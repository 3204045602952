const Signup = () => {
  return (
    <div className="pt-32">
      <div className="max-w-[1300px] h-full m-auto flex justify-center gap-10">
        <div className="flex-1 rounded-3xl p-8">
          <h1 className="font-['Montserrat'] text-black-blue text-4xl font-bold ">
          Be a part of the kriya family!
          </h1>
        </div>

        <div className="flex-1">
          <h1 className="font-['Montserrat'] text-black-blue text-4xl font-bold ">
            Join the waitlist!
          </h1>

          <div className="mt-10 grid grid-cols-2 gap-x-5 gap-y-6">
            <div>
              <label className="font-['Montserrat'] text-steel text-sm">
                Name
              </label>

              <input
                type="text"
                className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                placeholder="John Doe"
              />
            </div>

            <div>
              <label className="font-['Montserrat'] text-steel text-sm">
                Company name
              </label>

              <input
                type="text"
                className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                placeholder="Company ABC"
              />
            </div>

            <div>
              <label className="font-['Montserrat'] text-steel text-sm">
                Email
              </label>

              <input
                type="email"
                className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                placeholder="name@email.com"
              />
            </div>

            <div>
              <label className="font-['Montserrat'] text-steel text-sm">
                Phone number
              </label>

              <input
                type="email"
                className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                placeholder="09XXXXXXXXX"
              />
            </div>

            <div>
              <label className="font-['Montserrat'] text-steel text-sm">
                Number of employees
              </label>

              <input
                type="text"
                className="outline-none font-['Montserrat'] px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue"
                placeholder="123"
              />
            </div>

            <div className="col-span-2">
              <label className="font-['Montserrat'] text-steel text-sm">
                Comments/Inquiries
              </label>

              <textarea className="font-['Montserrat'] outline-none px-3 py-2 rounded-lg border border-gray-300 w-full focus:border-black-blue h-[100px] resize-none" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
