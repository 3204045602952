const AccentCirclesRight = ({ className }) => {
  return (
    <svg
      viewBox="0 0 298 522"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M236.058 143.109C222.284 78.0004 158.336 36.3854 93.2265 50.16C106.999 115.26 170.93 156.872 236.03 143.116C170.937 156.903 129.336 220.842 143.109 285.941C129.334 220.832 65.3861 179.217 0.277032 192.992C14.0516 258.101 77.9996 299.716 143.109 285.941C77.9996 299.716 36.3848 363.664 50.1592 428.773C115.257 415.001 156.869 351.072 143.116 285.974C156.905 351.065 220.842 392.663 285.94 378.891C220.831 392.665 179.216 456.614 192.991 521.722C258.1 507.948 299.715 443.999 285.94 378.891C351.049 365.116 392.664 301.168 378.89 236.059C392.664 301.168 456.613 342.783 521.722 329.008C507.947 263.9 443.998 222.285 378.89 236.059C365.115 170.95 301.167 129.335 236.058 143.109ZM329.008 0.277801C263.899 14.0523 222.284 78.0004 236.058 143.109C301.167 129.335 342.782 65.3869 329.008 0.277801ZM428.772 471.84C363.663 485.615 299.715 443.999 285.94 378.891C351.049 365.116 414.998 406.732 428.772 471.84ZM471.839 93.2273C485.614 158.336 443.998 222.285 378.89 236.059C365.115 170.95 406.731 107.002 471.839 93.2273Z"
        fill="#CC5500"
        fill-opacity="0.1"
      />
    </svg>
  );
};

export default AccentCirclesRight;
