const KriyaPayroll = ({ className }) => {
  return (
    <svg
      viewBox="0 0 700 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M683.988 89.9658V16.458H699.443V89.9658H683.988Z"
        fill="#36454F"
      />
      <path
        d="M654.188 89.9658V16.458H669.642V89.9658H654.188Z"
        fill="#36454F"
      />
      <path
        d="M614.636 90.7584C608.957 90.7584 603.904 89.5696 599.479 87.192C595.12 84.8144 591.653 81.5782 589.077 77.4834C586.567 73.3226 585.312 68.6004 585.312 63.3168C585.312 57.9672 586.567 53.245 589.077 49.1502C591.653 44.9894 595.12 41.7532 599.479 39.4417C603.904 37.064 608.957 35.8752 614.636 35.8752C620.25 35.8752 625.27 37.064 629.695 39.4417C634.12 41.7532 637.587 44.9564 640.097 49.0512C642.606 53.1459 643.861 57.9012 643.861 63.3168C643.861 68.6004 642.606 73.3226 640.097 77.4834C637.587 81.5782 634.12 84.8144 629.695 87.192C625.27 89.5696 620.25 90.7584 614.636 90.7584ZM614.636 78.0778C617.212 78.0778 619.524 77.4834 621.571 76.2946C623.618 75.1058 625.237 73.4217 626.425 71.2422C627.614 68.9967 628.209 66.3549 628.209 63.3168C628.209 60.2127 627.614 57.5709 626.425 55.3915C625.237 53.212 623.618 51.5279 621.571 50.339C619.524 49.1502 617.212 48.5558 614.636 48.5558C612.061 48.5558 609.749 49.1502 607.702 50.339C605.654 51.5279 604.003 53.212 602.748 55.3915C601.56 57.5709 600.965 60.2127 600.965 63.3168C600.965 66.3549 601.56 68.9967 602.748 71.2422C604.003 73.4217 605.654 75.1058 607.702 76.2946C609.749 77.4834 612.061 78.0778 614.636 78.0778Z"
        fill="#36454F"
      />
      <path
        d="M547.379 89.9659V36.6678H562.14V51.726L560.06 47.367C561.645 43.6025 564.187 40.7626 567.688 38.8473C571.188 36.8659 575.448 35.8752 580.467 35.8752V50.1409C579.807 50.0749 579.212 50.0418 578.684 50.0418C578.156 49.9758 577.594 49.9428 577 49.9428C572.773 49.9428 569.339 51.1646 566.697 53.6083C564.121 55.9859 562.833 59.7174 562.833 64.8029V89.9659H547.379Z"
        fill="#36454F"
      />
      <path
        d="M494.911 109.977C492.137 109.977 489.396 109.548 486.688 108.69C483.98 107.831 481.768 106.642 480.051 105.123L485.698 94.1267C486.886 95.1834 488.24 96.0089 489.759 96.6034C491.344 97.1978 492.896 97.495 494.415 97.495C496.595 97.495 498.312 96.9666 499.567 95.9099C500.888 94.9192 502.077 93.2351 503.133 90.8575L505.907 84.319L507.096 82.6349L526.216 36.6677H541.076L517.003 93.2351C515.286 97.528 513.304 100.896 511.059 103.34C508.879 105.784 506.436 107.501 503.728 108.491C501.086 109.482 498.147 109.977 494.911 109.977ZM504.025 92.0463L480.249 36.6677H496.199L514.625 81.2479L504.025 92.0463Z"
        fill="#36454F"
      />
      <path
        d="M460.691 89.9659V79.5639L459.7 77.2853V58.6607C459.7 55.3584 458.676 52.7827 456.629 50.9334C454.648 49.0842 451.577 48.1596 447.416 48.1596C444.576 48.1596 441.769 48.6219 438.995 49.5465C436.287 50.4051 433.976 51.5939 432.06 53.1129L426.513 42.3146C429.419 40.2672 432.919 38.6821 437.014 37.5594C441.108 36.4366 445.269 35.8752 449.496 35.8752C457.62 35.8752 463.927 37.7905 468.418 41.6211C472.909 45.4517 475.155 51.4288 475.155 59.5523V89.9659H460.691ZM444.444 90.7584C440.283 90.7584 436.716 90.065 433.744 88.678C430.772 87.225 428.494 85.2767 426.909 82.8331C425.324 80.3894 424.531 77.6486 424.531 74.6105C424.531 71.4403 425.291 68.6665 426.81 66.2889C428.395 63.9112 430.872 62.062 434.24 60.7411C437.608 59.3542 442 58.6607 447.416 58.6607H461.582V67.6758H449.1C445.467 67.6758 442.958 68.2702 441.571 69.459C440.25 70.6478 439.589 72.1338 439.589 73.917C439.589 75.8984 440.349 77.4834 441.868 78.6722C443.453 79.795 445.6 80.3564 448.307 80.3564C450.883 80.3564 453.195 79.762 455.242 78.5732C457.289 77.3183 458.775 75.5021 459.7 73.1245L462.078 80.2573C460.955 83.6916 458.908 86.3004 455.936 88.0836C452.964 89.8668 449.133 90.7584 444.444 90.7584Z"
        fill="#36454F"
      />
      <path
        d="M357.98 89.9659V20.6189H387.998C394.206 20.6189 399.556 21.6426 404.047 23.69C408.538 25.6713 412.005 28.5443 414.449 32.3088C416.892 36.0734 418.114 40.5644 418.114 45.782C418.114 50.9334 416.892 55.3915 414.449 59.156C412.005 62.9206 408.538 65.8265 404.047 67.8739C399.556 69.8553 394.206 70.8459 387.998 70.8459H366.897L374.029 63.614V89.9659H357.98ZM374.029 65.3972L366.897 57.7691H387.106C392.06 57.7691 395.758 56.7124 398.202 54.5989C400.645 52.4855 401.867 49.5465 401.867 45.782C401.867 41.9514 400.645 38.9793 398.202 36.8659C395.758 34.7525 392.06 33.6958 387.106 33.6958H366.897L374.029 26.0676V65.3972Z"
        fill="#36454F"
      />
      <path
        d="M328.437 89.9659V79.5639L327.446 77.2853V58.6607C327.446 55.3584 326.422 52.7827 324.375 50.9334C322.394 49.0842 319.323 48.1596 315.162 48.1596C312.322 48.1596 309.515 48.6219 306.741 49.5465C304.033 50.4051 301.722 51.5939 299.806 53.1129L294.259 42.3146C297.165 40.2672 300.665 38.6821 304.76 37.5594C308.855 36.4366 313.015 35.8752 317.242 35.8752C325.366 35.8752 331.673 37.7905 336.164 41.6211C340.655 45.4517 342.901 51.4288 342.901 59.5523V89.9659H328.437ZM312.19 90.7584C308.029 90.7584 304.463 90.065 301.491 88.678C298.519 87.225 296.24 85.2767 294.655 82.8331C293.07 80.3894 292.277 77.6486 292.277 74.6105C292.277 71.4403 293.037 68.6665 294.556 66.2889C296.141 63.9112 298.618 62.062 301.986 60.7411C305.354 59.3542 309.746 58.6607 315.162 58.6607H329.328V67.6758H316.846C313.214 67.6758 310.704 68.2702 309.317 69.459C307.996 70.6478 307.336 72.1338 307.336 73.917C307.336 75.8984 308.095 77.4834 309.614 78.6722C311.199 79.795 313.346 80.3564 316.053 80.3564C318.629 80.3564 320.941 79.762 322.988 78.5732C325.036 77.3183 326.522 75.5021 327.446 73.1245L329.824 80.2573C328.701 83.6916 326.654 86.3004 323.682 88.0836C320.71 89.8668 316.879 90.7584 312.19 90.7584Z"
        fill="#36454F"
      />
      <path
        d="M245.02 109.977C242.246 109.977 239.505 109.548 236.798 108.69C234.09 107.831 231.877 106.642 230.16 105.123L235.807 94.1267C236.996 95.1834 238.35 96.0089 239.869 96.6034C241.454 97.1978 243.006 97.495 244.525 97.495C246.704 97.495 248.422 96.9666 249.676 95.9099C250.997 94.9192 252.186 93.2351 253.243 90.8575L256.017 84.319L257.205 82.6349L276.325 36.6677H291.186L267.112 93.2351C265.395 97.528 263.414 100.896 261.168 103.34C258.989 105.784 256.545 107.501 253.837 108.491C251.195 109.482 248.256 109.977 245.02 109.977ZM254.134 92.0463L230.358 36.6677H246.308L264.735 81.2479L254.134 92.0463Z"
        fill="#36454F"
      />
      <path
        d="M208.484 89.9658V36.6677H223.938V89.9658H208.484ZM216.211 29.2377C213.371 29.2377 211.06 28.4121 209.276 26.761C207.493 25.1099 206.602 23.0625 206.602 20.6188C206.602 18.1752 207.493 16.1278 209.276 14.4767C211.06 12.8256 213.371 12 216.211 12C219.051 12 221.363 12.7925 223.146 14.3776C224.929 15.8966 225.821 17.878 225.821 20.3216C225.821 22.8974 224.929 25.0438 223.146 26.761C221.429 28.4121 219.117 29.2377 216.211 29.2377Z"
        fill="#36454F"
      />
      <path
        d="M166.109 89.9659V36.6678H180.87V51.726L178.79 47.367C180.375 43.6025 182.918 40.7626 186.418 38.8473C189.919 36.8659 194.178 35.8752 199.198 35.8752V50.1409C198.537 50.0749 197.943 50.0418 197.415 50.0418C196.886 49.9758 196.325 49.9428 195.73 49.9428C191.504 49.9428 188.069 51.1646 185.427 53.6083C182.852 55.9859 181.564 59.7174 181.564 64.8029V89.9659H166.109Z"
        fill="#36454F"
      />
      <path
        d="M113.78 79.0684L114.176 60.2457L139.042 36.6677H157.468L133.593 60.9392L125.569 67.4776L113.78 79.0684ZM101 89.9658V16.458H116.454V89.9658H101ZM140.726 89.9658L122.696 67.5767L132.404 55.5895L159.45 89.9658H140.726Z"
        fill="#36454F"
      />
      <circle cx="42" cy="42" r="42" fill="#CC5500" />
      <circle cx="42" cy="82" r="42" fill="#008080" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 61C12.109 47.8968 25.9863 39 41.9415 39C57.8967 39 71.774 47.8968 78.883 61C71.774 74.1032 57.8967 83 41.9415 83C25.9863 83 12.109 74.1032 5 61Z"
        fill="#666B40"
      />
    </svg>
  );
};

export default KriyaPayroll;
