import HeroImage from "../svgs/HeroImage";

const HeroBanner = () => {
  return (
    <div className="pt-32 box-border p-10 flex flex-col md:flex-row justify-center gap-10 md:gap-5 items-center min-h-screen">
      <div>
        <p className="text-[20px] sm:text-[30px] text-black-blue font-['Montserrat'] font-bold">
          Augmented Intelligence <br /> that fosters an engaged workspace.
        </p>

        <p className="mt-5 font-['Poppins'] text-[12px] sm:text-[16px] text-black">
          Manage your workforce efficiently with our comprehensive HRIS
          solution. <br /> From employee onboarding to payroll, we've got you
          covered.
        </p>

        {/* <div className="mt-5 flex flex-col sm:flex-row gap-3">
          <button 
          onClick={() => window.location.href = "#contact"}
          className="font-['Poppins'] bg-black-blue py-3 px-5 text-white rounded-[8px] text-[12px] sm:text-[16px]">
            Get Started
          </button>

          <button 
          onClick={() => window.location.href = "#contact"}
          className="font-['Poppins'] border border-black-blue py-3 px-5 text-black-blue rounded-[8px] text-[12px] sm:text-[16px]">
            Schedule a Demo
          </button>
        </div> */}
      </div>
      <a href="https://storyset.com">
        <HeroImage />
      </a>
    </div>
  );
};

export default HeroBanner;
