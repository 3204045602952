const DemoIllustration = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      className={height + " animate-up-down-1"}
      viewBox="0 0 813.03586 658.84956"
    >
      <path
        d="M1001.69141,156.04543H198.30859a4.50492,4.50492,0,0,1-4.5-4.5V125.07522a4.50492,4.50492,0,0,1,4.5-4.5h803.38282a4.50492,4.50492,0,0,1,4.5,4.5v26.47021A4.50492,4.50492,0,0,1,1001.69141,156.04543Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#e4e4e4"
      />
      <circle
        id="b6c858f0-1926-4bcd-807b-9efaf247b9cf"
        data-name="Ellipse 90"
        cx="26.44196"
        cy="16.444"
        r="6.38888"
        fill="#fff"
      />
      <circle
        id="b369121d-4d8f-4c1b-b23a-4a491aa6d487"
        data-name="Ellipse 91"
        cx="50.69203"
        cy="16.444"
        r="6.38888"
        fill="#fff"
      />
      <circle
        id="a3a83b48-e804-404e-aa0f-53d13c62451a"
        data-name="Ellipse 92"
        cx="74.94323"
        cy="16.444"
        r="6.38888"
        fill="#fff"
      />
      <path
        d="M995.608,779.42478H638.29324a10.92217,10.92217,0,0,1-10.90986-10.90986V515.93309a10.92221,10.92221,0,0,1,10.90986-10.90987H995.60807a10.92222,10.92222,0,0,1,10.90986,10.90989V768.515A10.92221,10.92221,0,0,1,995.608,779.42478Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#e6e6e6"
      />
      <path
        d="M980.73245,519.89732H653.17331a10.92879,10.92879,0,0,0-10.91478,10.91475V753.63526A10.92892,10.92892,0,0,0,653.17331,764.55H980.73245A10.92185,10.92185,0,0,0,991.639,753.63526V530.812A10.92176,10.92176,0,0,0,980.73245,519.89732Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#fff"
      />
      <circle
        cx="803.93159"
        cy="615.98268"
        r="43.98705"
        transform="translate(-169.80066 1141.12436) rotate(-77.07016)"
        fill="#9e616a"
      />
      <path
        d="M930.43872,723.12982a43.18661,43.18661,0,0,0-30.17635-32.5209l-.03277-.01767a18.83457,18.83457,0,0,0-4.51049-1.54753L842.8569,678.22767l-10.5908-11.36412-45.06554,2.87653-4.70432,13.07246-51.76666,23.00658.85818-.60191a54.61976,54.61976,0,0,0-6.4572,3.09469l-.26218.1167a16.76162,16.76162,0,0,0-4.11487,2.62482c-13.1644,9.13626-21.86233,23.80155-22.184,40.15358l-1.36663,13.34308H939.22982Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#363636"
      />
      <path
        d="M852.07042,592.40664c-3.601-11.24785-14.13669-20.74562-25.94322-20.44367.91987-2.80574-.65114-5.95174-3.00232-7.73741-2.35164-1.78577-5.32492-2.5-8.21579-3.10245-5.97519-1.2452-12.20246-2.19587-18.14288-.79294-5.9404,1.40286-11.56969,5.66459-12.90716,11.6201-5.41043-5.07153-15.41524-3.66847-19.22195,2.69577-1.95907,3.27516-2.23672,7.67544-.11948,10.85047,2.10368,3.15457,6.08837,4.575,9.87586,4.7536,3.78793.1788,7.53824-.67115,11.30923-1.0672,9.00784-.946,18.52209.86949,25.80393,6.25524,7.28186,5.38569,11.93523,14.621,10.64009,23.58512,2.911-1.86032,7.10441-.56839,9.13741,2.22451s2.19352,6.609,1.24906,9.932c-.94445,3.32291-2.8612,6.27148-4.75141,9.163q-5.22084,7.98666-10.44122,15.9733l1.4256.9484c5.2503,2.21406,10.93025-1.92519,14.75279-6.15094A85.19957,85.19957,0,0,0,848.8755,626.9497C853.44076,616.05732,855.67184,603.65464,852.07042,592.40664Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#2f2e41"
      />
      <path
        d="M662.31964,583.83543H207.37293a13.9066,13.9066,0,0,1-13.89086-13.89085V248.34788A13.90658,13.90658,0,0,1,207.37293,234.457H662.31964a13.90661,13.90661,0,0,1,13.89085,13.89087v321.5967A13.90659,13.90659,0,0,1,662.31964,583.83543Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#e6e6e6"
      />
      <path
        d="M643.37948,253.39527H226.31886a13.915,13.915,0,0,0-13.89714,13.89708V550.99927a13.915,13.915,0,0,0,13.89714,13.89714H643.37948a13.90608,13.90608,0,0,0,13.88664-13.89714V267.29235A13.906,13.906,0,0,0,643.37948,253.39527Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#fff"
      />
      <path
        d="M494.85742,386.53706v0l-.76923,1.38658c6.84673-16.87383.62081-37.66636-11.59318-50.38973-12.21352-12.72338-29.19316-18.5401-45.91248-20.84778-12.41157-1.71309-25.29459-1.66358-37.09612,2.88273-17.09012,6.58366-30.14732,21.82429-41.049,37.67876-4.98663,7.25165-9.88945,15.51891-9.39271,24.5826.46116,8.41117,5.45388,15.54893,9.54253,22.68511,4.08912,7.13618,7.50359,15.98984,4.569,23.77849-1.74866,4.64141-5.44451,7.98418-8.44323,11.781-2.99872,3.7968-5.46558,8.9288-4.017,13.69269,1.17186,3.85357,4.69729,6.38832,8.32291,7.23322,3.62515.84483,7.38607.29279,11.05757-.262l95.29985-14.39837A40.61538,40.61538,0,0,0,494.85742,386.53706Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#2f2e41"
      />
      <circle cx="230.48975" cy="278.50691" r="46.5939" fill="#ffb9b9" />
      <path
        d="M540.18889,504.56028a40.03637,40.03637,0,0,0-33.9883-23.34l-12.98556-.786.03137.13613v.00006h0l-33.01074-19.85775c.81183.9297-53.69511,2.87653-53.69511,2.87653l-20.22416,17.44765-8.48162.51342a40.03489,40.03489,0,0,0-33.98784,23.34l-27.54281,60.33613H371.2006l1.27346-5.481q2.078,2.53188,4.03013,5.151h191.227Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#363636"
      />
      <path
        d="M468.16717,355.83746c-1.20276-6.3628-7.69227-9.273-13.25242-10.68314a127.65817,127.65817,0,0,0-49.38548-2.7119c-12.01455,1.71338-24.31421,5.49145-33.0318,14.86935-6.799,7.31433-10.91291,17.79223-11.23315,28.61045-.18634,6.305.94105,12.86611,4.07789,18.176,3.1373,5.30979,8.48443,9.16125,14.00432,9.026l.559,1.9442c23.3933-3.25933,45.1236-17.81488,58.80956-39.3918a50.46667,50.46667,0,0,1-14.74405,23.81853,91.86657,91.86657,0,0,0,39.65661-30.5629C466.39509,365.259,469.082,360.67825,468.16717,355.83746Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#2f2e41"
      />
      <path
        d="M977.93149,132.62184H950.00662a2.06851,2.06851,0,1,1,0-4.137h27.92487a2.06851,2.06851,0,0,1,0,4.137Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#fff"
      />
      <path
        d="M977.93149,140.37875H950.00662a2.06851,2.06851,0,1,1,0-4.137h27.92487a2.06851,2.06851,0,0,1,0,4.137Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#fff"
      />
      <path
        d="M977.93149,148.13566H950.00662a2.06851,2.06851,0,1,1,0-4.137h27.92487a2.06851,2.06851,0,0,1,0,4.137Z"
        transform="translate(-193.48207 -120.57522)"
        fill="#fff"
      />
    </svg>
  );
};

export default DemoIllustration;
