import ContactUs from "../components/home/ContactUs";
import Core from "../components/home/Core";
import HeroBanner from "../components/home/HeroBanner";
import HowItWorks from "../components/home/HowItWorks";
import WebFooter from "../components/home/Webfooter";


const Home = () => {

  return (
    <>
      <div>
        <HeroBanner />
        <Core />
        <HowItWorks />
        <ContactUs />
        <WebFooter />
      </div>
    </>
  );
};

export default Home;
