const TrianglesLeft = ({ className }) => {
  return (
    <svg
      viewBox="0 0 443 805"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M130.898 363.657C112.048 386.113 112.048 418.886 130.898 441.341L285.282 625.28C318.25 664.56 290.363 724.5 239.12 724.5H-155.865C-207.107 724.5 -234.996 664.56 -202.029 625.28L-47.6426 441.341C-28.7941 418.886 -28.7941 386.113 -47.643 363.657L-202.029 179.718C-234.996 140.441 -207.107 80.5 -155.865 80.5H239.12C290.359 80.5 318.25 140.441 285.282 179.718L130.898 363.657Z"
        fill="#CC5500"
        fill-opacity="0.1"
      />
    </svg>
  );
};

export default TrianglesLeft;
