import { Outlet } from "react-router-dom";
import { useRef } from "react";
import NavBar from "./home/NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Layout = () => {
  const navBarRef = useRef(null);
  const chevronRef = useRef(null);
  const listRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  //Check Mobile Screen Device

  function detectMobileDevice() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
  }

  const handlebNav = () => {
    if (navBarRef.current.classList.contains("h-screen")) {
      navBarRef.current.classList.add("h-0");
      navBarRef.current.classList.remove("h-screen");
    } else {
      navBarRef.current.classList.remove("h-0");
      navBarRef.current.classList.add("h-screen");
    }
  };

  const handleSolution = () => {
    if (chevronRef.current.classList.contains("rotate-180")) {
      chevronRef.current.classList.remove("rotate-180");
      listRef.current.classList.add("h-0");
      listRef.current.classList.remove("h-[35px]");
    } else {
      chevronRef.current.classList.add("rotate-180");
      listRef.current.classList.remove("h-0");
      listRef.current.classList.add("h-[35px]");
    }
  };

  return (
    <>
      <div
        ref={navBarRef}
        className="transition-all ease-in-out duration-500 h-0 w-full overflow-hidden bg-white flex flex-col items-center sm:hidden justify-center gap-5 fixed z-20"
      >
        <button onClick={handlebNav} className="absolute top-7 right-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="fill-white w-10"
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </button>

        <button
          onClick={handleSolution}
          className="text-[24px] font-medium text-black-blue opacity-100 relative"
        >
          Solutions
          <svg
            ref={chevronRef}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="transition-all ease-in-out h-7 w-7 fill-black-blue absolute -right-7 top-1"
          >
            <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
          </svg>
        </button>

        <div
          ref={listRef}
          className="-mt-5 transition-all ease-in-out duration-300 overflow-hidden flex flex-col justify-center-items-center gap-3 h-0"
        >
          <p
            onClick={() =>
              (detectMobileDevice) ? window.location.href = "https://www.kriyahr.com/payroll-b" : window.location.href = "https://www.kriyahr.com/payroll-a"
            }
            className="text-lg text-center font-semibold text-black-blue cursor-pointer"
          >
            Payroll
          </p>
        </div>

        <HashLink
          onClick={handlebNav}
          to={pathname !== "/" ? "/#about" : "#about"}
          className="text-[24px] font-medium text-black-blue opacity-100 select-none"
        >
          About
        </HashLink>

        <HashLink
          onClick={handlebNav}
          to={pathname !== "/" ? "/#contact" : "#contact"}
          className="text-[24px] font-medium text-black-blue opacity-100 select-none"
        >
          Contact us
        </HashLink>

        <p
          onClick={() =>
            (window.location.href = "https://www.kriyahr.com/signup")
          }
          className="text-[24px] font-medium text-black-blue opacity-100 cursor-pointer"
        >
          Sign up
        </p>

        <p
          onClick={() => (window.location.href = "https://app.kriyahr.com")}
          className="text-[24px] font-medium text-black-blue opacity-100 cursor-pointer"
        >
          Log in
        </p>
      </div>
      <NavBar navBarRef={navBarRef} />

      <Outlet />
    </>
  );
};

export default Layout;
