import { useEffect, useRef } from "react";

const FadeInLeft = ({ children, className }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove("translate-x-10");
            entry.target.classList.add("opacity-100");
            entry.target.classList.add("translate-x-0");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={`${className} opacity-0 transition-all duration-1000 ease-in-out translate-x-10`}
    >
      {children}
    </div>
  );
};

export default FadeInLeft;