import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Payroll from "./pages/Payroll";
import Layout from "./components/Layout";
import PayrollB from "./pages/PayrollB";
import Signup from "./pages/Signup";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/payroll-b" element={<Payroll />} />
          <Route path="/payroll-a" element={<PayrollB />} />
          <Route path="/signup" element={<Signup />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
