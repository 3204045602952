import Webfooter from "../components/home/Webfooter";
import FadeInUp from "../components/transitions/FadeInUp";
const TermsOfService = () => {
  return (
    <div className=" flex flex-col pt-32">
      <div>
        <div className="max-w-[1300px] m-auto box-border p-10 min-h-screen  text-black-blue ">
          <FadeInUp>
            <h1 className=" text-center lg:text-left text-3xl md:text-5xl font-bold">
              Terms of Service
            </h1>

            <p className="text-sm md:text-lg py-12">
              Welcome to <span className="font-bold">kriyahr.com </span>
              ("Website") and our online services ("Services"). By accessing or
              using our Website and Services, you agree to comply with and be
              bound by the following terms and conditions ("Terms of Use").
              Please read these terms carefully before using our Website and
              Services. If you do not agree with any part of these Terms of Use,
              please do not use our Website or Services.
            </p>

            <h3 className="text-2xl font-bold">1. Acceptance of Terms</h3>
            <p className="text-sm pt-5 md:text-lg pb-12">
              By accessing and using our Website and Services, you acknowledge
              that you have read, understood, and agree to be bound by these
              Terms of Use, as well as our Privacy Policy. This agreement
              constitutes a legally binding contract between you and kriyaHR.
            </p>

            <h3 className="text-2xl font-bold">2. Changes to Terms</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              kriyaHR reserves the right to update or modify these Terms of Use
              at any time without prior notice. Any changes will be effective
              immediately upon posting on this page. Your continued use of the
              Website and Services after any modifications indicates your
              acceptance of the updated Terms of Use. We encourage you to review
              these terms periodically.
            </p>

            <h3 className="text-2xl font-bold">
              3. Use of the Website and Services
            </h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              You agree to use the Website and Services only for lawful purposes
              and in accordance with these Terms of Use. You are responsible for
              ensuring that your use complies with all applicable laws,
              regulations, and company policies.
            </p>

            <h3 className="text-2xl font-bold">4. User Accounts</h3>
            <p className="text-sm py-5  md:text-lg ">
              Some features of our Website and Services may require you to
              create an account. When creating an account, you agree to:
            </p>

            <p className="text-sm md:text-lg">
              <ul className="list-outside px-5 pb-5 pt-2">
                <li>
                  <ul className="list-disc list-inside">
                    <li>
                      Provide accurate, current, and complete information.
                    </li>
                    <li>
                      Maintain and promptly update your account information.
                    </li>
                    <li>Keep your username and password confidential.</li>
                    <li>
                      Accept responsibility for all activities that occur under
                      your account.
                    </li>
                    <li>
                      You must not share your account credentials with others or
                      use another person's account without permission.
                    </li>
                    <li>
                      You agree to notify kriyaHR immediately of any
                      unauthorized use of your account.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <h3 className="text-2xl font-bold">5. User Conduct</h3>
            <p className="text-sm py-5  md:text-lg ">
              You agree not to engage in any of the following prohibited
              activities:
            </p>

            <p className="text-sm md:text-lg">
              <ul className="list-outside px-5 pb-5 pt-2">
                <li>
                  <ul className="list-disc list-inside">
                    <li>Violating any applicable laws or regulations.</li>
                    <li>
                      Infringing upon or violating our intellectual property
                      rights or the rights of others.
                    </li>
                    <li>
                      Uploading or transmitting viruses, malware, or any other
                      harmful code.
                    </li>
                    <li>
                      Interfering with or disrupting the integrity or
                      performance of the Website or Services.
                    </li>
                    <li>
                      Attempting to gain unauthorized access to any part of the
                      Website or Services.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>

            <h3 className="text-2xl font-bold">6. Content You Provide</h3>
            <p className="text-sm pt-5  md:text-lg pb-2 ">
              You are responsible for any content, data, or information you
              upload, submit, or transmit through our Website and Services
              ("User Content"). By providing User Content, you grant kriyaHR a
              non-exclusive, worldwide, royalty-free license to use, store,
              reproduce, modify, and display such content solely for the purpose
              of providing the Services.
            </p>

            <p className="text-sm md:text-lg  pb-12">
              {" "}
              You represent and warrant that you have all necessary rights to
              provide the User Content and that such content does not infringe
              upon any third-party rights.
            </p>

            <h3 className="text-2xl font-bold">7. Privacy Policy</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              Your use of our Website and Services is also governed by our
              Privacy Policy, which outlines how we collect, use, and protect
              your personal information. By using the Website and Services, you
              consent to the processing of your personal data as described in
              the Privacy Policy.
            </p>

            <h3 className="text-2xl font-bold">
              8. Personal Information Processing Consent
            </h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              You agree to use the Website and Services only for lawful purposes
              and in accordance with these Terms of Use. You are responsible for
              ensuring that your use complies with all applicable laws,
              regulations, and company policies.
            </p>

            <h3 className="text-2xl font-bold">
              3. Use of the Website and Services
            </h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              By using our Website and Services, you agree and consent that
              kriyaHR may collect, use, retain, disclose, and process personal
              information for purposes including but not limited to:
            </p>

            <p className="text-sm md:text-lg">
              <ul className="list-outside px-5 pb-5 pt-2">
                <li>
                  <ul className="list-disc list-inside">
                    <li>Providing and improving our products and services.</li>
                    <li>Facilitating transactions.</li>
                    <li>
                      Communicating about relevant products, services, and
                      advisories.
                    </li>
                    <li>
                      Enhancing customer experience and developing new
                      offerings.
                    </li>
                    <li>Complying with legal requirements and processes.</li>
                    <li>
                      Conducting statistical, analytical, and research
                      activities.
                    </li>
                    <li>
                      Identifying and preventing errors or misuse of the
                      platform.
                    </li>
                    <li>
                      You are responsible for obtaining consent from your
                      employees and referrals before providing their personal
                      information to us.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>

            <h3 className="text-2xl font-bold">
              9. Cookies and Tracking Technologies
            </h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              Our Website and Services use cookies and similar technologies to
              enhance user experience and analyze website performance. By using
              our Website and Services, you consent to our use of cookies in
              accordance with our Privacy Policy. You may adjust your browser
              settings to refuse cookies, but some features may not function
              properly.
            </p>

            <h3 className="text-2xl font-bold">
              10. Intellectual Property Rights
            </h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              All content on the Website and Services, including text, graphics,
              logos, images, software, and trademarks, is the property of
              kriyaHR or its licensors and is protected by intellectual property
              laws. You may not reproduce, distribute, modify, or create
              derivative works from any content without our prior written
              consent.
            </p>

            <h3 className="text-2xl font-bold">
              11. Modification of the Website and Services
            </h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              kriyaHR reserves the right to modify, suspend, or discontinue any
              aspect of the Website or Services at any time, including the
              availability of any feature or content, without prior notice. We
              may also impose limits on certain features or restrict your access
              to parts or all of the Website or Services without liability.
            </p>

            <h3 className="text-2xl font-bold">
              12. Links to Third-Party Websites
            </h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              Our Website and Services may contain links to third-party websites
              or services that are not owned or controlled by kriyaHR. These
              links are provided for your convenience and do not imply
              endorsement. We have no control over, and assume no responsibility
              for, the content, privacy policies, or practices of any
              third-party websites or services. Your access and use of such
              websites are at your own risk.
            </p>

            <h3 className="text-2xl font-bold">13. Disclaimer of Warranties</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              The Website and Services are provided on an "as is" and "as
              available" basis without warranties of any kind, either express or
              implied. kriyaHR does not warrant that the Website and Services
              will be uninterrupted, error-free, secure, or free of harmful
              components. Your use of the Website and Services is at your own
              risk.
            </p>

            <h3 className="text-2xl font-bold">14. Limitation of Liability</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              To the fullest extent permitted by applicable law, kriyaHR and its
              affiliates, officers, directors, employees, and agents shall not
              be liable for any direct, indirect, incidental, special,
              consequential, or punitive damages arising out of or related to
              your use or inability to use the Website or Services, even if
              advised of the possibility of such damages.
            </p>

            <h3 className="text-2xl font-bold">15. Termination</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              We may terminate or suspend your access to the Website and
              Services immediately, without prior notice or liability, for any
              reason, including but not limited to your breach of these Terms of
              Use. Upon termination, your right to use the Website and Services
              will cease immediately.
            </p>

            <h3 className="text-2xl font-bold">16. Governing Law</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              These Terms of Use shall be governed by and construed in
              accordance with the laws of the Republic of the Philippines,
              without regard to its conflict of law provisions. Any disputes
              arising from these Terms of Use shall be resolved exclusively in
              the courts of the Philippines.
            </p>

            <h3 className="text-2xl font-bold">17. Severability</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              If any provision of these Terms of Use is found to be invalid or
              unenforceable by a court of competent jurisdiction, the remaining
              provisions shall remain in full force and effect.
            </p>

            <h3 className="text-2xl font-bold">18. Waiver</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              No waiver of any term or condition of these Terms of Use shall be
              deemed a further or continuing waiver of such term or any other
              term, and kriyaHR's failure to assert any right or provision under
              these Terms of Use shall not constitute a waiver of such right or
              provision.
            </p>

            <h3 className="text-2xl font-bold">19. Contact Us</h3>
            <p className="text-sm pt-5  md:text-lg  pb-12">
              If you have any questions or concerns about these Terms of Use,
              please contact us at:{" "}
              <span className="font-bold">tech@kriyahr.com</span>
            </p>

            <p className="text-sm md:text-lg pt-12 pb-2">
              These Terms of Use constitute a legal agreement between you and
              kriyaHR. Please ensure that you understand all aspects before
              using our Website and Services.
            </p>
            <p className="text-sm md:text-lg  pt-2 pb-12">
              {" "}
              By using our Website and Services, you acknowledge that you have
              read, understood, and agreed to these Terms of Use.
            </p>
          </FadeInUp>
        </div>
        <Webfooter />
      </div>
    </div>
  );
};

export default TermsOfService;
