import ContactUsImage from "../svgs/ContactUsImage";

const ContactUs = () => {
  return (
    <div className="bg-black-blue pt-32" id="contact">
      <div className="max-w-[1300px] m-auto p-10 ">
        <p className="text-[20px] sm:text-[30px] text-white font-bold font-['Montserrat']">
          Get Into the Waiting List & Connect with Us!
        </p>

        <div className="flex flex-col md:flex-row justify-center md:items-center gap-16 mt-10">
          <div className="flex-1">
            <a href="https://storyset.com">
              {" "}
              <ContactUsImage />
            </a>
          </div>

          <div className="flex-1">
            <form action="https://formspree.io/f/xqazzjbe" method="POST">
              <div className="max-w-[500px] m-auto flex flex-col gap-8">
                <div className="flex flex-col">
                  <label className="text-[14px] mb-1 text-white font-['Poppins']">
                    Your Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Full Name"
                    className="transition outline-none bg-white font-['Poppins'] text-[14px] text-[#363636] border border-[#e4e4e4] focus:border-[#6f97b0] rounded-[8px] p-3"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-[14px] mb-1 text-white font-['Poppins']">
                    Email Address
                  </label>
                  <input
                    name="email"
                    type="email"
                    placeholder="email@company.com"
                    className="transition outline-none bg-white font-['Poppins'] text-[14px] text-[#363636] border border-[#e4e4e4] focus:border-[#6f97b0] rounded-[8px] p-3"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-[14px] mb-1 text-white font-['Poppins']">
                    Message/Inquiry
                  </label>
                  <textarea
                    name="message"
                    placeholder="Enter a message or an inquiry."
                    className="transition outline-none bg-white font-['Poppins'] text-[14px] text-[#363636] border border-[#e4e4e4] focus:border-[#6f97b0] rounded-[8px] p-3 h-[150px] resize-none"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="border border-white rounded-[8px] p-3 text-white text-[14px]"
                >
                  Send
                </button>
              </div>
            </form>
            {/* <form action="https://formspree.io/f/xqazzjbe" method="POST">
                <div className="flex flex-col">
                  <label className="text-[14px] mb-1 text-white font-['Poppins']">
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    placeholder="FirstName LastName"
                    className="transition outline-none bg-white font-['Poppins'] text-[14px] text-[#363636] border border-[#e4e4e4] focus:border-[#6f97b0] rounded-[8px] p-3"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-[14px] mb-1 text-white font-['Poppins']">
                    Email Address
                  </label>
                  <input
                    name="email"
                    type="email"
                    placeholder="email@company.com"
                    className="transition outline-none bg-white font-['Poppins'] text-[14px] text-[#363636] border border-[#e4e4e4] focus:border-[#6f97b0] rounded-[8px] p-3"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-[14px] mb-1 text-white font-['Poppins']">
                    Message/Inquiry
                  </label>
                  <textarea
                    name="message"
                    placeholder="Enter a message or an inquire"
                    className="transition outline-none bg-white font-['Poppins'] text-[14px] text-[#363636] border border-[#e4e4e4] focus:border-[#6f97b0] rounded-[8px] p-3 h-[150px] resize-none"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="border border-white rounded-[8px] p-3 text-white text-[14px]"
                >
                  Send
                </button>
              </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
