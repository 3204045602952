const CirclesRight = ({ className }) => {
  return (
    <svg
      viewBox="0 0 341 626"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_5944_16791)">
        <mask
          id="mask0_5944_16791"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="626"
          height="626"
        >
          <path d="M0 0H626V626H0V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_5944_16791)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M313 626C140.136 626 -2.28882e-05 485.864 -2.28882e-05 313C-2.28882e-05 140.135 140.136 0 313 0C485.865 0 626 140.135 626 313C626 485.864 485.865 626 313 626ZM313 588.44C160.879 588.44 37.56 465.121 37.56 313C37.56 160.879 160.879 37.56 313 37.56C465.121 37.56 588.44 160.879 588.44 313C588.44 465.121 465.121 588.44 313 588.44ZM313 550.88C181.621 550.88 75.12 444.379 75.12 313C75.12 181.623 181.621 75.12 313 75.12C444.377 75.12 550.88 181.623 550.88 313C550.88 444.379 444.377 550.88 313 550.88ZM313 513.32C202.367 513.32 112.68 423.633 112.68 313C112.68 202.366 202.367 112.68 313 112.68C423.634 112.68 513.32 202.366 513.32 313C513.32 423.633 423.634 513.32 313 513.32ZM150.24 313C150.24 402.89 223.11 475.76 313 475.76C402.89 475.76 475.76 402.89 475.76 313C475.76 223.11 402.89 150.24 313 150.24C223.11 150.24 150.24 223.11 150.24 313ZM187.8 313C187.8 382.145 243.855 438.2 313 438.2C382.146 438.2 438.2 382.145 438.2 313C438.2 243.854 382.146 187.8 313 187.8C243.855 187.8 187.8 243.854 187.8 313Z"
            fill="#666A40"
            fill-opacity="0.1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5944_16791">
          <rect
            width="626"
            height="626"
            rx="10"
            transform="matrix(-1 0 0 1 626 0)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CirclesRight;
