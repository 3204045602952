const FlowerLeft = ({ className }) => {
  return (
    <svg
      viewBox="0 0 315 578"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_5944_16667)">
        <mask
          id="mask0_5944_16667"
          maskUnits="userSpaceOnUse"
          x="-263"
          y="0"
          width="578"
          height="578"
        >
          <path
            d="M-262.998 108.256L206.688 0L314.943 469.686L-154.742 577.942L-262.998 108.256Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5944_16667)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M87.5886 27.4506L205.01 0.386631L232.074 117.808C245.856 177.604 212.536 237.262 156.42 257.916C216.553 250.985 273.578 290.214 287.493 350.587L314.557 468.009L197.136 495.073C137.34 508.855 77.6813 475.533 57.0278 419.419C63.9584 479.552 24.7294 536.576 -35.644 550.491L-153.065 577.555L-180.129 460.134C-193.911 400.338 -160.59 340.679 -104.475 320.026C-164.608 326.957 -221.633 287.728 -235.548 227.355L-262.612 109.933L-145.19 82.8694C-85.3945 69.0874 -25.7357 102.407 -5.08301 158.523C-12.0137 98.3911 27.2159 41.3657 87.5886 27.4506Z"
            fill="#008080"
            fill-opacity="0.1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5944_16667">
          <rect
            width="482"
            height="482"
            rx="10"
            transform="matrix(-0.974452 0.224597 0.224597 0.974452 206.688 0)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FlowerLeft;
